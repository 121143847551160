

<!-- <div class="wrapper">
   
        <app-navigation></app-navigation>
       
</div> -->

<div class="wrapper">
    <div class="sidebar" data-color="danger" data-background-color="white" data-image="./assets/img/sidebar-1.jpg">
        <app-navigation></app-navigation>
        <div class="sidebar-background" ></div>
    </div>
    <div class="main-panel">
      
        <app-header></app-header>
        <router-outlet></router-outlet>
        
    </div>
   
</div>
import { Component, OnInit } from '@angular/core';
import { apiClass } from '../../providers/app_users'
import { Router } from '@angular/router';
declare const $: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {
  public email: any;
  public password: any;
  isLogin=false
  constructor(public router: Router, public apis: apiClass) {

  }

  ngOnInit(): void {
    console.log("enter");
    //this.getsample();

  }
  getsample() {
    this.apis.sendGetRequest('http://127.0.0.1:2233/users').subscribe((res) => {
      console.log("hfjgh ghf jhg");
      console.log(res, "working");
    })
  }
  postsample() {

    this.apis.sendPostRequest('login', {
      email: this.email,
      password: this.password
    }).subscribe((res) => {
      console.log(res, "post wrking");
    })
  }
  gotonextinput(){
    $('#psd').focus();
  }

  onLogin() {
    this.isLogin=true

    this.apis.sendPostRequest('login', {
      email: this.email,
      password: this.password
    }).subscribe((res) => {
      setTimeout(() => {
      this.isLogin=false
          
        // location.reload();
      }, 5000);
      // console.log(res["status"], "dfgv fdgfdsg")
      if (res["status"] == true) {
        this.apis.showNotification('success', res['message']);
        console.log(res["status"], "dfgv----------- fdgfdsg")
        localStorage.setItem('username', 'xploreAdmin');
        this.router.navigate(['/']);
        setTimeout(() => {
          
          location.reload();
        }, 100);
      }else{
        this.apis.showNotification('danger', res['message']);
      }

      console.log(res, "post wrking");
    },
      err => {


      })

  }



}

<h1 mat-dialog-title></h1>
<div mat-dialog-content>
  <p>{{msg}}</p>
  <!-- <mat-form-field>
    <mat-label>Favorite Animal</mat-label>
    <input matInput [(ngModel)]="data.animal">
  </mat-form-field> -->
</div>
<div mat-dialog-actions class="pull-right">
  <button mat-button  mat-raised-button
  class="btn  " (click)="onNoClick()">No</button>
  <button  mat-raised-button
  class="btn  " mat-button (click)="continue()" cdkFocusInitial>Yes</button>
</div>

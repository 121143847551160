import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './pages/login/login.component';
import { LeftNavTemplateComponent } from './pages/template/left-nav-template.component';


let a = localStorage.getItem("username");
// console.log(a)
const routes: Routes = [

  a == 'xploreAdmin' ? {
    path: '',
    component: LeftNavTemplateComponent,
    data: {
      title: 'User Management'
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: {
          title: 'Dashboard Page'
        },
      },
      {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
        data: {
          title: 'Login Page'
        },
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: {
          title: 'Dashboard Page'
        },
      },
      
      
      {
        path: 'user_management',
        loadChildren: () => import('./pages/user-management/user-management.module').then(m => m.UserManagementModule),
        data: {
          title: 'User Management'
        },
      },
    

      {
        path: 'user_details',
        loadChildren: () => import('./pages/user-details/user-details.module').then(m => m.UserDetailsModule),
        data: {
          title: 'user_management'
        },
      },
      {
        path: 'physicians_details',
        loadChildren: () => import('./pages/user-details/user-details.module').then(m => m.UserDetailsModule),
        data: {
          title: 'user_management'
        },
      },
      {
        path: 'cms',
        loadChildren: () => import('./pages/cms/cms.module').then(m => m.CmsModule),
        data: {
          title: 'user_management'
        },
      },
      {
        path: 'cms_details/:id',
        loadChildren: () => import('./pages/add-cms/add-cms.module').then(m => m.AddCmsModule),
        data: {
          title: 'user_management'
        },
      },
      {
        path: 'occupation',
        loadChildren: () => import('./pages/occupation/occupation.module').then(m => m.OccupationModule),
        data: {
          title: 'user_management'
        },
      },
      {
        path: 'speciality',
        loadChildren: () => import('./pages/speciality/speciality.module').then(m => m.SpecialityModule),
        data: {
          title: 'user_management'
        },
      },
      {
        path: 'email_details/:id',
        loadChildren: () => import('./pages/email-details/email-details.module').then(m => m.EmailDetailsModule),
        data: {
          title: 'user_management'
        },
      },
      {
        path: 'email_management',
        loadChildren: () => import('./pages/email-management/email-management.module').then(m => m.EmailManagementModule),
        data: {
          title: 'user_management'
        },
      },
      {
        path: 'faq_management',
        loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqModule),
        data: {
          title: 'user_management'
        },
      },
      {
        path: 'faq_details',
        loadChildren: () => import('./pages/faq-details/faq-details.module').then(m => m.FaqDetailsModule),
        data: {
          title: 'user_management'
        },
      },
      {
        path: 'faq_details/:id',
        loadChildren: () => import('./pages/faq-details/faq-details.module').then(m => m.FaqDetailsModule),
        data: {
          title: 'user_management'
        },
      },
      {
        path: 'safety_tips',
        loadChildren: () => import('./pages/safety-tips/safety-tips.module').then(m => m.SafetyTipsModule),
        data: {
          title: 'user_management'
        },
      },
      {
        path: 'safety_tips_details',
        loadChildren: () => import('./pages/safety-tips-details/safety-tips-details.module').then(m => m.SafetyTipsDetailsModule),
        data: {
          title: 'user_management'
        },
      },
      {
        path: 'safety_tips_details/:id',
        loadChildren: () => import('./pages/safety-tips-details/safety-tips-details.module').then(m => m.SafetyTipsDetailsModule),
        data: {
          title: 'user_management'
        },
      },
      {
        path: 'settings',
        loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule),
        data: {
          title: 'user_management'
        },
      },
      {
        path: 'edit-settings/:id',
        loadChildren: () => import('./pages/settings-detail/settings-detail.module').then(m => m.SettingsDetailModule),
        data: {
          title: 'user_management'
        },
      },
      {
        path: 'reports',
        loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule),
        data: {
          title: 'user_management'
        },
      },
      {
        path: 'push-notifications',
        loadChildren: () => import('./pages/push-notifications/push-notifications.module').then(m => m.PushNotificationsModule),
        data: {
          title: 'user_management'
        },
      },
      {
        path: 'country-reports',
        loadChildren: () => import('./pages/country-report/country-report.module').then(m => m.CountryReportModule),
        data: {
          title: 'user_management'
        },
      },
      {
        path: 'subscription-list',
        loadChildren: () => import('./pages/subscription-list/subscription-list.module').then(m => m.SubscriptionListModule),
        data: {
          title: 'user_management'
        },
      },
      {
        path: 'change-password',
        loadChildren: () => import('./pages/change-password/change-password.module').then(m => m.ChangePasswordModule),
        data: {
          title: 'user_management'
        },
      },
    ]
  } : {
      path: '**',
      component: LoginComponent,
      data: {
        title: 'User Management'
      },


    },










];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<div class="logo">
    <a href="#" class="simple-text text-white">

        <!-- <img style="padding-top: 7px;" src="./assets/img/logo.png"/> -->

        <span style="margin-left:-100px">
            <img style="padding-top: 7px;width:90px" src="https://xploredate.s3.us-east-2.amazonaws.com/logo.svg"/>
         </span>
    </a>
</div>
<div class="sidebar-wrapper">

    <ul class="nav">
        <li routerLinkActive="active" *ngFor="let menuItem of menuItems" class="{{menuItem.class}} nav-item">
            <a class="nav-link" [routerLink]="[menuItem.path]">
                <i class="material-icons">{{menuItem.icon}}</i>
                <p>{{menuItem.title}}</p>
            </a>
        </li>
        <li>
            <a (click)="logout()" href="javascript:void(0)" class="nav-link">
                <i class="material-icons">logout</i>
                <p>Logout</p>
            </a>
        </li>
    </ul>

</div>
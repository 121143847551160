import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms'; 
import { HashLocationStrategy, LocationStrategy  } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { ImageCropperModule } from 'ngx-image-cropper';
import {NgxPaginationModule} from 'ngx-pagination'; 
import { NgxUiLoaderModule } from 'ngx-ui-loader';

import {  HeaderComponent } from './pages/header/header.component';
import { NavigationComponent } from './pages/navigation/navigation.component';
import { BlankTemplateComponent } from './pages/template/blank-template.component';
import { LeftNavTemplateComponent } from './pages/template/left-nav-template.component';


import {MatDialogModule} from '@angular/material/dialog';
import { NavService } from './pages/nav.service';
import { apiClass } from './providers/app_users';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';

import {MatSidenavModule} from '@angular/material/sidenav'
import {MatListModule} from '@angular/material/list'
import {MatMenuModule} from '@angular/material/menu'
import {MatIconModule} from '@angular/material/icon'
import {MatToolbarModule} from '@angular/material/toolbar';
import { ConfirmationComponent } from './pages/confirmation/confirmation.component';

import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
// import { OrderManagementComponent } from './pages/order-management/order-management.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NavigationComponent,
    BlankTemplateComponent,
    LeftNavTemplateComponent,
    ConfirmationComponent,
    
   
  

    // OrderManagementComponent,
   
    

  
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ImageCropperModule,
    NgxPaginationModule,
    NgxUiLoaderModule,
    NoopAnimationsModule,
    MatSidenavModule,
    MatListModule,
    MatMenuModule,
    MatIconModule,
    MatToolbarModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule
   
  ],
  providers: [apiClass,NavService,MatDatepickerModule,
    {provide : LocationStrategy , useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }

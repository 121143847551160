import { Component, OnInit,ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {MatSidenavModule , MatSidenav} from '@angular/material/sidenav'
import { ConfirmationComponent } from '../confirmation/confirmation.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: '/dashboard', title: 'Dashboard',  icon: 'dashboard', class: '' },
  
    { path: '/user_management', title: 'User Management',  icon:'admin_panel_settings', class: '' },
   
    // { path: '/cms', title: 'CMS Management',  icon:'description', class: '' },
    // { path: '/email_management', title: 'Email Management',  icon:'email', class: '' },
    // { path: '/faq_management', title: 'FAQ Management',  icon:'article', class: '' },
    { path: '/reports', title: 'Reported Profiles',  icon:'report_problem', class: '' },
    // { path: '/safety_tips', title: 'Safety Tips',  icon:'security', class: '' },
    // { path: '/push-notifications', title: 'Push Notifications',  icon:'perm_phone_msg', class: '' },
    // { path: '/country-reports', title: 'Country wise Reports',  icon:'map-marker', class: '' },
    
    { path: '/subscription-list', title: 'Subscription Report',  icon:'article', class: '' },
    { path: '/settings', title: 'Settings',  icon:'settings', class: '' },
    { path: '/cms', title: 'Content Management',  icon:'settings', class: '' },
    // { path: '/login', title: 'Logout',  icon:'logout', class: '' },


    // { path: '/change-password', title: 'Change Password',  icon:'security', class: '' },
   
];
 
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  @ViewChild('sidenav') sidenav: MatSidenav;
  isExpanded = true;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;

  menuItems: any[];
  constructor(private router: Router,public dialog: MatDialog) { }

  
  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
      // if ($(window).width() > 991) {
      //     return false;
      // }
      return true;
  };
  logout(){
   

    const dialogRef = this.dialog.open(ConfirmationComponent, {
      // width: '250px',
      data: {message:'Are you sure want to logout?'},
      disableClose:true
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result.dat) {
         
          localStorage.setItem('username', '');
          location.reload();
          this.router.navigate(['/login' ]);
      }
    
    });
  }

 

}






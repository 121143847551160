import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './blank-template.component.html'
})
export class BlankTemplateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

<div class="main-content">
    <div class="container-fluid">
        <div class="row">

            <div class="col-12">

                <div class="card max-box col-4" style="margin: 0 auto; margin-top: 10%;">
                    <div class="card-body p-4">
                        <img style="width: 30%;" class="m-auto d-flex " src="./assets/login.png" alt="unloaded">
                        <div class="navbar-form">
                            <div class="input-group no-border my-4">
                                <input (keyup.enter)="gotonextinput()" type="text" [(ngModel)]="email" class="form-control" placeholder="User Name">
                            </div>
                            <div class="input-group no-border mb-4">
                                <input id="psd" (keyup.enter)="onLogin()" type="password" [(ngModel)]="password" class="form-control" placeholder="Password">
                            </div>
                            <button (click)="onLogin()" (keyup.enter)="onLogin()" class="btn-danger btn-block p-2 text-white" [disabled]="isLogin">LOGIN</button>
                        </div>
                    </div>
                </div>

            </div>


        </div>
    </div>
</div>
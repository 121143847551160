import { Component, OnInit ,Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
  msg:any;
  constructor(
    public dialogRef: MatDialogRef<ConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.msg = data.message;
    }

  onNoClick(): void {
    let data = {dat:false}
    this.dialogRef.close(data);
  }
  continue() {
    let data = {dat:true}
    this.dialogRef.close(data);
  }

  ngOnInit(): void {
  }

}
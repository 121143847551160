// import { HttpClient } from '@angular/common/http';
// export class AppUser {


//     constructor(public http: HttpClient) {
//       this.controller = this.baseurl + this.controller;
//       this.httpOptions = this.headerOptions('application/json');
//     }



//   }


import { Component, OnInit, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import {environment} from '../../environments/environment'
declare var $: any;
@Injectable()
export class apiClass {
  base: any;
  constructor(public httpClient: HttpClient) {
  //  this.base = 'https://coptic-match.com:3333/admin/'
    //this.base = 'http://3.23.222.79:2234/admin/'
  //  this.base = 'http://localhost:3001/admin/'
   //this.base = 'https://coptic-match.newagesme.com:3333/admin/';
  
 }
 getUrl(action: string) {
  return this.base =environment.url+action;
}
  sendGetRequest(api) {
    return this.httpClient.get('http://127.0.0.1:2233//users')
  }
  showNotification(colors = 'danger' , message = 'CopticMatch'){
    
    const type = ['','info','success','warning','danger'];
    

    $.notify({
        icon: "notifications",
        message: message

    },{
        type: colors,
        timer: 500,
        placement: {
            from: 'top',
            align: 'right'
        },
        template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
            '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          '</div>' +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
}


  sendPostRequest(api, data) {

    let httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    });
    let options = {
      headers: httpHeaders
    };
    return this.httpClient.post(this.getUrl(api),
      data,
      options)
  }






}